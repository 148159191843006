
import { defineStore } from 'pinia'

export const useReportLink = defineStore('reportLink', {
	state: () => ({
		reportLink: false,
		reportTitle: null,
		reportContacts: null,
		reportID: null,
		allow_share: null,
		demo: false
	}),

	actions: {
		reportSelected (data, type) {
			this.reportLink = data.report_link
			this.reportTitle = data.report_title
			this.allow_share = data.category.allow_share
			this.reportID = data.id
			let contacts = [];
			if(data.contact_email_cst_id){
				contacts.push(data.contact_email_cst_id)
			}
			if(data.contact_email_2_id){
				contacts.push(data.contact_email_2_id)
			}
			if(data.contact_email_3_id){
				contacts.push(data.contact_email_3_id)
			}
			if(type){
				this.demo = true;
			}else{
				this.demo = false;
			}
			this.reportContacts = contacts
		}
	}
})
